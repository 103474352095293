import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import PageHeader from '../components/sections/PageHeader'
import { StaticImage } from 'gatsby-plugin-image'

import { default as SbsIHCB } from '../components/sections/SidebysideImageHeadingContentButton'

const AboutPage = props => {
  return (
    <>
      <Layout>
        <SEO />
        <PageHeader
          heading="The Majiska Way"
          content="Our strategic marketing strategy has evolved and proved over time to be highly successful. It is as important to your company's overall business’s digital success as it is to ours. A system that is organic in nature adapts to a variety of conditions in accordance with shifting market needs, environmental factors, and evolving technologies over time. We actively employ the fine-tuned Majiska way that includes measuring, refining, and reengineering as a way to insure the best possible results for our valued clients."
        />

        <main className="content-main">
          <SbsIHCB
            heading="Formula for Success"
            content={
              <p>
                A clear recipe for replicable success at Majiska is a carefully curated mix of fine-tuned methodology, loveable brains, and effective tools. We work hard to cultivate our signature blend to achieve top-level outcomes for all of our customers. Our strategy is forever evolving, remains innovative to adjust to the multitude of different technology, markets, and business conditions. We tackle each and every bit of data on every project here through careful measurements, analysis and refinement .
              </p>
            }
            image={<StaticImage src="../images/malaysia-best-digital-marketing-company.png" alt="A dinosaur" />}
            isImageSquare
            padding="py-0"
          />
          <SbsIHCB
            isEven={true}
            heading="Technology"
            content={
              <p>
                Modern technologies deliver greater levels of optimisation and efficiency that are almost impossible for humans to match. Using artificial intelligence (AI), learning algorithms, predictive data analysis, and process automation, our technical specialists uncover new opportunities and ideas that provide significantly higher return on investment (ROI). More importantly, rather than just number crunching, our expertise is used in high-value activities such as strategy, creative and analysis.
              </p>
            }
            image={<StaticImage src="../images/malaysia-digital-marketing-technology-expert.png" alt="A dinosaur" />}
            isImageSquare
            padding="py-0"
          />
          <SbsIHCB
            heading="Data"
            content={
              <p>
                Data is central to business performance in the digital age. A profound understanding of key data points is essential in modern marketing regardless of one's research, implementation or measurement. We are heavily invested in intelligence platforms that support our endless search for digital indicators and metrics. When you have the right data, you get better results, perform better, and maintain transparency in your strategy.
              </p>
            }
            image={<StaticImage src="../images/malaysia-digital-marketing-data.png" alt="A dinosaur" />}
            isImageSquare
            padding="py-0"
          />
          <SbsIHCB
            isEven
            isImageSquare
            heading="Methodology"
            content={
              <p>
                Our remarkable results of the campaign are based on a refined methodology. We believe that continuous analysis, hypothesis, testing and enhancement results in optimal performance. This process guarantees that our campaigns meet our clients' business goals. Our quest for digital excellence is endless.
              </p>
            }
            image={<StaticImage src="../images/strategic-digital-marketing-growth-malaysia.png" alt="A dinosaur" />}
            padding="py-0"
          />
          <SbsIHCB
            heading="People"
            content={
              <p>
                Without brilliant people who are capable of using these technologies, processes and data, these sophisticated strategies mean very little. Our team of outstanding digital strategists are among the most recognised in Malaysia. They have been curated over years and trained to the elite level and are behind the success of our customers
              </p>
            }
            image={<StaticImage src="../images/majiska-digital-marketing-experts-specialists.png" alt="A dinosaur" />}
            isImageSquare
            padding="py-resp-16-24 lg:pt-0"
          />
        </main>
      </Layout>
    </>
  )
}

export default AboutPage
